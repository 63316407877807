// === App.js ===

import React, { useState, useEffect, useRef, lazy, Suspense, useMemo } from 'react';
import 'buffer';                                                    // 2024-05-21: global polyfills for craco, for monaco editor
import 'process/browser';                                           // 2024-05-21: global polyfills for craco, for monaco editor
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthProvider } from './AuthContext';                               // 2024-06-11
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';      // 2024-06-05
import he from 'he';
import { IconButton, Typography } from '@mui/material';                     // 2024-04-30-A
import DescriptionIcon from '@mui/icons-material/Description';              // 2024-04-30-A

// import TwoColumnLayout from './TwoColumnLayout';

import MainTabContent from './MainTabContent';                              // 2024-04-30-A

// import PublicPage from './PublicPage';
// import Waitlist from './Waitlist';
// import Construction from './Construction';
// import NotFoundPage from './404';
// import ValidateEmailAddress from './ValidateEmailAddress';
// import Pricing from './Pricing';
// import AdPage from './AdPage';

import LoginPage from './LoginPage';
import SignUpPage from './SignUpPage';
import SplashPage from './SplashPage';

// Tried some stuff like this:
//      const ChatLayout = React.lazy(() => import('./ChatLayout'));
// Ran into headaches (maybe check notes). 

import PageLoadingPlaceholder from './PageLoadingPlaceholder';  // 2024-07-30-C

// //import ChatLayout from './ChatLayout';
//import ChatLayoutSkeleton from './ChatLayoutSkeleton';
// //const ChatLayout = lazy(() => import('./ChatLayout'));
// const ChatLayout = useMemo(() => {
//   return lazy(() => import('./ChatLayout'));
// }, [])

import { GoogleOAuthProvider } from '@react-oauth/google';                          // 2024-09-10-E
import { GOOGLE_CLIENT_ID } from './constants';                                     // 2024-09-10-E


// Note: WDYR is in index.js                                                      // 2024-05-29-D #dev-only

const theme = createTheme({
  // your theme customization
});

// 2024-08-06-C: above App declaration. exposed when trying to do swipe
// 2024-07-23-B
const ChatLayout = lazy(() => import('./ChatLayout'));
const PublicPage = lazy(() => import('./PublicPage'));
const Waitlist = lazy(() => import('./Waitlist'));
const Construction = lazy(() => import('./Construction'));
const NotFoundPage = lazy(() => import('./404'));
const ValidateEmailAddress = lazy(() => import('./ValidateEmailAddress'));
const Pricing = lazy(() => import('./Pricing'));
const AdPage = lazy(() => import('./AdPage'));


function App() {
    //const [currentPage, setCurrentPage] = useState('home'); // Example state to switch pages

    // // 2024-07-23-B
    // const ChatLayout = useMemo(() => {
    //     return lazy(() => import('./ChatLayout'));
    // }, []);
    
    // // 2024-07-23-D: [[ could implement a generic lazy load function here ]]
    // const PublicPage = useMemo(() => {
    //     return lazy(() => import('./PublicPage'));
    // }, []);
    // const Waitlist = useMemo(() => {
    //     return lazy(() => import('./Waitlist'));
    // }, []);
    // const Construction = useMemo(() => {
    //     return lazy(() => import('./Construction'));
    // }, []);
    // const NotFoundPage = useMemo(() => {
    //     return lazy(() => import('./404'));
    // }, []);
    // const ValidateEmailAddress = useMemo(() => {
    //     return lazy(() => import('./ValidateEmailAddress'));
    // }, []);
    // const Pricing = useMemo(() => {
    //     return lazy(() => import('./Pricing'));
    // }, []);
    // const AdPage = useMemo(() => {
    //     return lazy(() => import('./AdPage'));
    // }, []);    

    // 2024-06-22-S
    const [currentPage, setCurrentPage] = useState(() => getPageFromUrl());

    const moreTabRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Loading...');
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    
    // 2024-05-16-A
    const [snappyLoading, setSnappyLoading] = useState(false);
    const [snappyLoadingMessage, setSnappyLoadingMessage] = useState('Loading...');    

    // State to store the mapping between tab IDs and documentIDs
    // 2024-04-29-I
    const [tabDocumentMap, setTabDocumentMap] = useState({
        'tab_000': '000',
    });    
    // Function to update the mapping
    const updateTabDocumentMap = (tabId, documentId) => {
        setTabDocumentMap(prevMap => ({ ...prevMap, [tabId]: documentId }));
    };

    // for /chat
    // function getPageFromUrl() {
    //     console.log("Inside getPageFromUrl");
    //     // This gets the pathname like '/keyportion' from 'domain.com/keyportion'
    //     const pathName = window.location.pathname;
    
    //     // 2024-06-04
    //     const searchParams = new URLSearchParams(window.location.search);
    //     const token = searchParams.get('token');
    //     if (pathName === '/validate_email' && token) {
    //         return { page: 'validate_email', token: token };
    //     }    
    
    //     // If the pathname is just '/', return 'home', otherwise return the part after '/'
    //     return pathName === '/' ? 'home' : pathName.substring(1);
    // }    

    function getPageFromUrl() {
        console.log("Inside getPageFromUrl");
        const pathName = window.location.pathname;
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get('token');
    
        if (pathName.includes('validate_email') && token) {
            console.log("In App.js, token: ", token);
            return { page: 'validate_email', token: token };
        } else {
            console.log("In App.js, no token");
        }
    
        // Remove '/main' from the beginning of the path if it exists
        const cleanPath = pathName.replace(/^\/main/, '');
        return cleanPath === '/' ? 'home' : cleanPath.substring(1);
    }

    useEffect(() => {
        const handleUrlChange = () => {
            setCurrentPage(getPageFromUrl());
        };

        window.addEventListener('popstate', handleUrlChange);

        // 2024-06-22-S
        // Call handleUrlChange immediately to set the correct page on initial load
        handleUrlChange();

        return () => {
            window.removeEventListener('popstate', handleUrlChange);
        };
    }, []);

    /* tabs are defined here so that Layout can call addNewTab even though the tabs are in e.g. ChatLayout */
    //let maxTabId = 8;
    const [maxTabId, setMaxTabId] = useState(8);
    const incrementMaxTabId = () => {
        setMaxTabId(prevMaxTabId => prevMaxTabId + 1);
    };   
    // This hasn't been tested yet. Called by delete user tabs.
    const resetMaxTabId = () => {
        // Find the highest ID among non-user-generated tabs
        const highestId = tabs.reduce((acc, tab) => {
            const tabIdNumber = parseInt(tab.id.replace('tab', ''), 10);
            if (!isNaN(tabIdNumber) && !tabDocumentMap[tab.id]) {
                return Math.max(acc, tabIdNumber);
            }
            return acc;
        }, 0); // Start with 0 as the accumulator value
    
        // If highestId is 0, it means either there are no non-user-generated tabs
        // or their IDs don't follow the 'tabX' format, so reset to a default value or keep as it is
        const newMaxTabId = highestId > 0 ? highestId : tabs.length + 1;
    
        setMaxTabId(newMaxTabId);
    };
    
    
    const showLoading = (message = 'Loading...') => {
        console.log("Inside showLoading with message: ", message);
        setLoadingMessage(message);
        console.log("Setting loading message ", message);
        setLoading(true);
    };
    const hideLoading = () => {
        setLoading(false);
        setLoadingMessage('Loading...'); // Reset to the default message
    };

    // 2024-05-16-A
    const showSnappyLoading = (message = 'Loading...', duration = 1000) => {
      setSnappyLoadingMessage(message);
      setSnappyLoading(true);
      setTimeout(() => {
        setSnappyLoading(false);
      }, duration);
    };
    
    // 2024-04-29-H
    const [isAdminMode, setIsAdminMode] = useState(false);
    const [adminTabData, setAdminTabData] = useState([
        { id: 'tab1', label: 'Board', content: 'Lorem Ipsum', isAdmin: true },
        { id: 'tab2', label: 'Convo', content: 'Content for Tab 2', isAdmin: true },
        { id: 'tab3', label: 'WM', content: 'Content for Tab 3', isAdmin: true },
        { id: 'tab4', label: 'Rules', content: 'Content for Tab 4', isAdmin: true },
        { id: 'tab5', label: 'OA', content: 'Content for Tab 5', isAdmin: true },
        { id: 'tab6', label: 'BM', content: 'Content for Tab 6', isAdmin: true },
        { id: 'tab7', label: 'Err', content: 'Content for Tab 7', isAdmin: true },
        { id: 'tab8', label: 'Log', content: 'Content for Tab 8', isAdmin: true },
    ]);

    // 2024-05-23-L: prior toggle broken after adding main tab.
    // [[ this is still breaking some stuff. ]]
    const toggleAdminMode = () => {
        if (isAdminMode) {
            // Store admin tab data and delete admin tabs
            const adminTabs = tabs.filter(tab => tab.isAdmin);
            setAdminTabData(adminTabs);
            setTabs(tabs.filter(tab => !tab.isAdmin));
        } else {
            // Recreate admin tabs from stored data
            setTabs([...tabs.filter(tab => !tab.isAdmin), ...adminTabData]);
        }
        setIsAdminMode(prev => !prev);
    };    
    
    const [activeTab, setActiveTab] = useState(0);
    // 2024-04-30-A
    const [tabs, setTabs] = useState([
        { id: 'tab000', label: 'Main', content: <MainTabContent />, isAdmin: false }
    ]);
    // The methods passed in to maintabcontent are passed in in chatlayout, where we use the box / clone

    const handleChangeTab = (newActiveTab) => {
        setActiveTab(newActiveTab);
    };

    const printTabsInfo = () => {
        console.log('Current Tabs Info: ');
        tabs.forEach((tab, index) => {
            console.log(`Index: ${index}, Title: ${tab.label}, Visible: ${!tab.hidden}`);
        });
        console.log('Active Tab: ', activeTab);
    };    

    const addNewTab = () => {
        //console.log('Adding new tab');
        printTabsInfo(); // Call after updating tabs
        maxTabId++;
        //const newTabId = 'tab' + maxTabId;
        const newTabId = "tab" + (maxTabId + 1);    // increment locally, then increment globally afterward, avoiding a race. 
        console.log("Adding tab with newTabId ", newTabId);
        const newTabLabel = newTabId;
        const newTabContent = "Content for " + newTabLabel;
        const newTabs = [...tabs, { id:newTabId, label: newTabLabel, content: newTabContent, isAdmin: false }];
        const lastIndex = newTabs.length - 1;
        setTabs(newTabs);                           // might be flawed? compare with newer functions in ChatLayout. 

        incrementMaxTabId();

        setTimeout(() => {
            let maxActiveTabIndex = -1;
            for (let i = 0; i <= lastIndex; i++) {
                if (!newTabs[i].hidden) {
                    maxActiveTabIndex++;
                }
            }
            // 2024-05-22-I: unreliable, maybe undesirable 
            // console.log('After adding, updating active tab to ', maxActiveTabIndex);
            // if (maxActiveTabIndex !== -1) {
            //     setActiveTab(maxActiveTabIndex);
            // }            
        }, 500); 

    };

    // Function to delete user-generated tabs (e.g., when switching conversations.)
    // This is now called by "clear tabs", since it's senseless to keep a title for a user-defined tab/doc without content. 
    const deleteUserTabs = () => {
        console.log("Inside deleteUserTabs...");
        // Create a copy of the current tabs
        let newTabs = [...tabs];
        // Filter out user-generated tabs (those with a document ID)
        //newTabs = newTabs.filter(tab => !tabDocumentMap[tab.id]);
        newTabs = newTabs.filter(tab => tabDocumentMap[tab.id] === 'tab_000' || !tabDocumentMap[tab.id]); // 2024-04-29-I
        // Update the tabs state
        setTabs(newTabs);
        resetMaxTabId();
        setActiveTab(0);
    };
    
    const deleteLastTab = () => {
        console.log('Deleting last tab...');
        deleteTab(tabs.length-1);
    }
    
    const deleteTab = (tabIndexToDelete) => {
        console.log('Deleting tab with 0-index ', tabIndexToDelete);
        
        const newTabs = tabs.filter((_, index) => index !== tabIndexToDelete);
        setTabs(newTabs);
    
        // Adjust activeTab if necessary
        if (activeTab === tabIndexToDelete) {
            setActiveTab(newTabs.length > 0 ? Math.max(tabIndexToDelete - 1, 0) : 0);
        } else if (activeTab > tabIndexToDelete) {
            // Adjust activeTab index if a preceding tab was deleted
            setActiveTab(activeTab - 1);
        }
    };    

    const hideLastTab = () => {
         console.log('Hiding last tab');    
    };
    // not used; delete
    // hiding is too contrary to the way the UI works.
    // it's necessary to reestablish/redo the tabs to create the effect.

    const [moreMenuOpen, setMoreMenuOpen] = useState(false);
    const toggleMoreMenu = () => {
        setMoreMenuOpen(prev => !prev);
    };
    const [moreMenuTabs, setMoreMenuTabs] = useState([]);
    const MAX_TABS = 9;
    useEffect(() => {
        console.log("checking for need to modify More Tabs");
        if (tabs.length > MAX_TABS) {
            console.log("We have too many tabs! Will modify More Tabs");
            // Move the last tab to `moreMenuTabs`
            const lastTab = tabs[tabs.length - 1];
            setTabs(tabs.slice(0, -1));
            setMoreMenuTabs([...moreMenuTabs, lastTab]);
        } else {
            console.log("Number of tabs is within the tab limit.");
        }
    //}, [tabs]); // Depend on tabs    
    }, [tabs.length]);                  // 2024-05-28-E


    // Function to handle keyboard shortcuts
    const handleKeyboardShortcuts = (event) => {
        if (event.ctrlKey && event.key === 'j') {
            event.preventDefault(); // Prevent default behavior
            const nextTab = (activeTab + 1) % tabs.length; // Loop back to the first tab if at the end
            setActiveTab(nextTab);
        } else if (event.ctrlKey && event.shiftKey && event.key === 'A') {
            event.preventDefault();
            toggleAdminMode();
            console.log('Admin mode toggled through keyboard shortcut.');
        } 
        // maybe something for "More" menu.
    };
    useEffect(() => {
        // Attach the event listener
        window.addEventListener('keydown', handleKeyboardShortcuts);
        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('keydown', handleKeyboardShortcuts);
        };
    }, [activeTab, tabs.length]);        

    function encodeHtmlEntities(str) {
        // Note: we break up encoded HTML strings inside string literals, so they do not render 
        //  aka are not interpreted within web-based editing and viewing environments.        
        str = he.encode(str);
        str = str.replace(/\n/g, '<b' + 'r/>'); // Newline character to <br/> tag
        return str;
    }

    // 2024-06-05: with router, pageType isn't needed to figure out which page to show    
    // BUT it's used in Login and Sign Up components.
    // to get rid of it, we'd use the route in those places.
    // standardize the currentPage variable, for use in contained jsx files (e.g., conditional render of menu items in Layout.)
    let pageType;
    if(
        (currentPage === 'ChatLayout')
        ||
        (currentPage === 'chat')
    ) {
        pageType = 'chat';

    // } else if (currentPage === 'ckeditor_dialog') {
    //     pageType = 'ckeditor_dialog';
    // } else if (currentPage === 'test_ckeditor') {
    //     pageType = 'test_ckeditor';
    // } else if (currentPage === 'ckeditor_testpage') {        
    //     pageType = 'ckeditor_testpage';
    
    } else if (currentPage === 'privacy') {
        
        pageType = 'privacy';

    } else if (currentPage.page === 'validate_email') {
        pageType = 'validate_email';
        
    } else {
        //pageType = 'two_column';
        pageType = 'splash';
    }
    console.log("pageType: ", pageType);

    //const Placeholder = () => <div style={{ flexGrow: 1 }}>Loading...</div>;

    const ChatLayoutPlaceholder = () => (
      <div className="chatlayout_container" style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="chat-container" style={{ flexGrow: 1 }}>
          <div className="chat-display" style={{ flexGrow: 1 }}>Loading...</div>
        </div>
      </div>
    );
    
    //<Suspense fallback={<div>Loading...</div>}>
                // <Route path="/" exact element={<SplashPage 
                //       setIsUserLoggedIn={setIsUserLoggedIn}
                //     />} />
    return (
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <ThemeProvider theme={theme}>
            <BrowserRouter basename="/main">
            <AuthProvider>
              <Routes>
                <Route path="/" exact element={<SplashPage 
                    />} />
                <Route path="/chat" element={
                  <Suspense fallback={<PageLoadingPlaceholder />}>
                    <ChatLayout                     
                        tabs={tabs} 
                        setTabs={setTabs}
                        activeTab={activeTab}
                        addNewTab={addNewTab}
                        handleChangeTab={handleChangeTab}
                        hideLastTab={hideLastTab}
                        deleteLastTab={deleteLastTab}
                        moreMenuOpen={moreMenuOpen}
                        toggleMoreMenu={toggleMoreMenu}                    
                        moreTabRef={moreTabRef}
                        loading={loading}
                        setLoading={setLoading}
                        isUserLoggedIn={isUserLoggedIn}
                        setIsUserLoggedIn={setIsUserLoggedIn}
                        moreMenuTabs={moreMenuTabs}
                        setMoreMenuTabs={setMoreMenuTabs}
                        pageType={pageType}
                        tabDocumentMap={tabDocumentMap}
                        updateTabDocumentMap={updateTabDocumentMap}
                        maxTabId={maxTabId}
                        setMaxTabId={setMaxTabId}      
                        encodeHtmlEntities={encodeHtmlEntities}
                        deleteUserTabs={deleteUserTabs}
                        deleteTab={deleteTab}
                        loadingMessage={loadingMessage}
                        setLoadingMessage={setLoadingMessage}
                        showLoading={showLoading}
                        hideLoading={hideLoading}
                        loadingMessage={loadingMessage}
                        isAdminMode={isAdminMode} 
                        toggleAdminMode={toggleAdminMode}
                        adminTabData={adminTabData} 
                        setAdminTabData={setAdminTabData}
                        setActiveTab={setActiveTab}
                        incrementMaxTabId={incrementMaxTabId}
                        snappyLoading={snappyLoading}
                        showSnappyLoading={showSnappyLoading}
                        setSnappyLoading={setSnappyLoading}
                        snappyLoadingMessage={snappyLoadingMessage}
                        setTabDocumentMap={setTabDocumentMap}
                    />
                    </Suspense>
                } />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/signup" element={<SignUpPage />} />
                    <Route path="/waitlist" element={<Suspense fallback={null}><Waitlist /></Suspense>} />
                    <Route path="/pricing" element={<Suspense fallback={null}><Pricing /></Suspense>} />
                    <Route path="/privacy" element={<Suspense fallback={null}><PublicPage pageName="privacy"/></Suspense>} />
                    <Route path="/status" element={<Suspense fallback={null}><PublicPage pageName="status"/></Suspense>} />
                    <Route path="/tos" element={<Suspense fallback={null}><PublicPage pageName="tos"/></Suspense>} />
                    <Route path="/404" element={<Suspense fallback={null}><NotFoundPage /></Suspense>} />
                    <Route path="/validate_email" element={<Suspense fallback={null}><ValidateEmailAddress token={currentPage.token} showLoading={showLoading} hideLoading={hideLoading} /> </Suspense>} />
                    <Route path="/Construction" element={<Suspense fallback={null}><Construction /> </Suspense>} />
                    {process.env.NODE_ENV === 'development' && (
                        <Route path="/adpage" element={<Suspense fallback={null}><AdPage /></Suspense>} />
                    )}
                    <Route path="*" element={<Suspense fallback={null}><NotFoundPage /> </Suspense>} />
              </Routes>
            </AuthProvider>
            </BrowserRouter>
        </ThemeProvider>
        </GoogleOAuthProvider>
    );
}

export default App;





